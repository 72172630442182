import React from 'react';
import './App.css';
import TrademarkSearchV2 from './components/TrademarkSearchV2';

function App() {
  return (
    <div className="App">
        <TrademarkSearchV2 />
    </div>
  );
}

export default App;
