import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TableFooter from '@material-ui/core/TableFooter';
import LinearProgress from '@material-ui/core/LinearProgress';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { FormGroup } from '@material-ui/core';
import InputAdornment from "@material-ui/core/InputAdornment";

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        //   backgroundColor: theme.palette.common.black,
        //   color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
        minWidth: 200,
    },
}));



function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};


const StyledTextField = withStyles({
    root: {
    //   background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    //   backgroundColor: 'red',
    //   borderRadius: 3,
    //   border: 0,
    //   color: 'white',
    //   height: 48,
    //   padding: '0 30px',
    //   boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    
    },
    adornedEnd: {
        backgroundColor:'red'
    }
    // label: {
    //   textTransform: 'capitalize',
    // },
  })(TextField);

export class TrademarkSearchV2 extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();

        this.state = {
            data: [],
            search: '',
            url: 'https://checkmarkswebapi.azurewebsites.net/api/trademark/',
            page: 0,
            rowsPerPage: 5,
            searchError: "",
            tableShown: false,
            cardShown: false,
            loading: false
        };

    }

    

    // generateRows = () => {
    //     console.log(this.state.data);
    //     return (this.state.data).map(row => {
    //         return <React.Fragment key={row.applicationNumberL[0]}>
    //         <TableRow>
    //             <StyledTableRow key={row.applicationNumberL[0]}>
    //                 <TableCell component="th" scope="row">
    //                     {row.heading}
    //                 </TableCell>
    //                 <StyledTableCell>{row.statusDescEn}</StyledTableCell>
    //                 <StyledTableCell align="right">{row.niceClasses.join(', ')}</StyledTableCell>
    //             </StyledTableRow>
    //         </TableRow>
    //         </React.Fragment>
    //     })
    // }

    getSearchTerms = async (e) => {
        e.preventDefault();
        var searchString = this.state.search;
        if (this.validateSearchString(searchString)) {
            let url = this.state.url + searchString;
            console.log(this.state.search);
            this.setState({
                loading: true,
            });
            await fetch(url)
                .then(response => response.json())
                .then(tempData => {
                    this.setState({
                        data: tempData.data,
                    })
                });
            this.setState({
                loading: false,
            });

            if (this.state.data.length == 0) {
                this.setState({
                    cardShown: true,
                    tableShown: false
                });
            } else {
                this.setState({
                    cardShown: false,
                    tableShown: true
                });
            }
        }

    }

    handleTextFieldChange = (event) => {
        this.setState({
            searchTerm: event.target.value
        });
    }

    test() {
        console.log(this.state.data);
    }

    validateSearchString(searchString) {
        let searchError = "";
        if (!searchString) {
            searchError = "Please enter a word that describes your trademark";
        }
        if (searchString.includes('/')) {
            searchError = "Your search cannot include '/'";
        }
        if (searchString.includes('.')
            || searchString.includes('?')
            || searchString.includes('#')
            || searchString.includes('&')
            || searchString.includes('*')
            || searchString.includes('\"')
            || searchString.includes('\\')
        ) {
            searchError = "Please do not include any special characters"
        }
        if (searchError) {
            this.setState({ searchError });
            return false;
        } else {
            this.setState({ searchError });
        }
        return true;
    }

    handleChange = (event) => {
        this.setState({
            search: event.target.value
        });
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage
        });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10)
        });
        this.setState({
            page: 0
        });
    };


    render() {

        return (
            <>
                <div id="tmsContainer">

                    <form onSubmit={this.getSearchTerms}>
                        <div id="tmsForm">
                            <div id="tmsSearchBarDiv" className="formDiv">
                                <StyledTextField
                                    id="search"
                                    placeholder="Search a trademark"
                                    fullWidth
                                    variant="outlined"
                                    value={this.state.search}
                                    defaultValue=""
                                    error={this.state.searchError != ""}
                                    helperText={this.state.searchError}
                                    onChange={this.handleChange}
                                    InputProps={{
                                        endAdornment:
                                            
                                            <IconButton type="submit" edge="end">
                                                <SearchIcon fontSize='inherit'/>
                                            </IconButton>
                                                
                                            
                                    }}
                                />
                            </div>
                            <div id="tmsSearchButtonDiv" className="formDiv">
                                {/* <Button color="primary" variant="contained" id="tmsSearchButton" type="submit">Search</Button> */}
                            </div>
                        </div>
                        <LinearProgress hidden={!this.state.loading} />
                    </form>



                    {this.state.tableShown &&
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table" table-layout="fixed">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell width="18%"><b>Trademark</b></StyledTableCell>
                                        <StyledTableCell width="18%"><b>Type</b></StyledTableCell>
                                        <StyledTableCell align="left" width="15%"><b>CIPO Status</b></StyledTableCell>
                                        <StyledTableCell align="left" width="17%"><b>Image</b></StyledTableCell>
                                        <StyledTableCell align="left" width="35%"><b>Nice Classes</b></StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.test()}

                                    {this.state.data != null && (this.state.rowsPerPage > 0
                                        ? (this.state.data).slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                        : this.state.data
                                    ).map((row) => (
                                        <StyledTableRow key={row.applicationNumberL[0]} width="18%">
                                            <TableCell component="th" scope="row">
                                                {row.title}
                                            </TableCell>
                                            {row.tmType[0] == 1 &&
                                                <TableCell component="th" scope="row">
                                                    Text
                                            </TableCell>
                                            }
                                            {row.tmType[0] == 2 &&
                                                <TableCell component="th" scope="row">
                                                    Image
                                            </TableCell>
                                            }
                                            {row.tmType[0] != 1 && row.tmType[0] != 2 &&
                                                <TableCell component="th" scope="row">
                                                    Other
                                            </TableCell>
                                            }
                                            <StyledTableCell align="left" width="15%">{row.statusDescEn}</StyledTableCell>
                                            {row.mediaUrls != null &&
                                                <StyledTableCell align="left" width="17%"><img className="tmsRowImage" src={row.mediaUrls[0]}></img></StyledTableCell>
                                            }
                                            {row.mediaUrls == null &&
                                                <StyledTableCell align="left" width="17%"><img className="tmsRowImage"></img></StyledTableCell>
                                            }
                                            <StyledTableCell align="left" width="35%">{row.niceClasses.join(', ')}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25, 50]}
                                            colSpan={5}
                                            count={this.state.data.length}
                                            rowsPerPage={this.state.rowsPerPage}
                                            page={this.state.page}
                                            SelectProps={{
                                                inputProps: { 'aria-label': 'rows per page' },
                                                native: true,
                                            }}
                                            onChangePage={this.handleChangePage}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    }
                    <div id="noSearchResults">
                        {this.state.cardShown &&
                            <Card id="resultcard" variant="outlined">
                                <CardContent>
                                    <Typography color="textPrimary" variant="h5">
                                        That trademark doesn't seem to be registered
                                    </Typography>

                                    <Typography color="textSecondary" variant="body1" >
                                        Click the button below to begin registering your new trademark
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button color="primary" variant="contained" size="large" href="https://checkmarks.azurewebsites.net/" style={{
                                        margin:
                                            'auto'
                                    }}>Register</Button>
                                </CardActions>
                            </Card>

                        }
                    </div>

                </div>
            </>
        );
    }
}

export default TrademarkSearchV2